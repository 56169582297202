
import { defineComponent, onMounted } from 'vue';
import Registar from '@/components/Registar.vue'

export default defineComponent({
  name: 'YoukoBtn',
  components: {
    Registar
  },
  
  data: function(){
    return{
      Open:false,
      Start:false,
      Regist:false,
      MailAdress:"",
      NickName:""
    }
  },

  methods: {
    JumpLogin(){
      const Loginurl = 'https://konnaku.jp/mem/top/'
      window.location.href = Loginurl
    },

    RegistarOn(){
      this.Regist = true
    },
  },

  mounted(){
    setTimeout(() => {
      this.Open = true;
    }, 3);

    setTimeout(() => {
      this.Start = true;
    }, 200);

    const setFillHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight);

    // 初期化
    setFillHeight();
  }
});
