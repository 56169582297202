import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Youko from '../views/Youko.vue'
import YoukoBtn from '../views/YoukoBtn.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/youko',
    name: 'Youko',
    component:Youko
  },
  {
    path: '/',
    name: 'YoukoBtn',
    component:YoukoBtn
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
